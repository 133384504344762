export const menu = [
  {
    label: "Home",
    path: "/",
  },
  {
    label: "Worksheets",
    path: "/worksheets",
  },

  {
    label: "Subjects",
    path: "/subjects",
    subMenu: ["English", "Maths", "Science", "Social Science"],
  },
  {
    label: "Plans/Pricing",
    path: "/plans-pricing",
  },
  {
    label: "Languages",
    path: "/languages",
  },
  // {
  //     label: 'FAQ',
  //     path: '/faq'
  // },
  {
    label: "Contact Us",
    path: "/contact-us",
  },
];