import React from "react";
import { Col, Row } from "react-bootstrap";
import OneIcon from "../assets/images/icons/icon12.png";
import TwoIcon from "../assets/images/icons/icon13.png";
import ThreeIcon from "../assets/images/icons/icon14.png";
import FourIcon from "../assets/images/icons/icon15.png";
import FiveIcon from "../assets/images/icons/icon16.png";
import MultiSupportImg from "../assets/images/graphic2.png";

export default function MultiLevelSupport() {
  return (
    <>
      <section className="section-bg ">
        <div className="container">
          <Row className="subjects">
            <Col md={6} className="order-lg-1">
              <img
                src={MultiSupportImg}
                alt="App Features"
                className="img-fluid"
              />
            </Col>
            <Col md={6} className="order-lg-0">
              <div className="text-center mb-3 mt-5">
                <h2 className="center">Multi Level Support</h2>
                <p>
                  Instantly convert image documents between 100+ supported
                  languages.
                </p>
              </div>
              <Row className="text-center steps mt-5">
                <Col lg={2} md={12}>
                  <div className="icon">
                    <img src={OneIcon} alt="One Icon" />
                  </div>
                  <p>Novice</p>
                </Col>
                <Col lg={2} md={12}>
                  <div className="icon">
                    <img src={TwoIcon} alt="Two Icon" />
                  </div>
                  <p>Beginner</p>
                </Col>
                <Col lg={2} md={12}>
                  <div className="icon">
                    <img src={ThreeIcon} alt="Three Icon" />
                  </div>
                  <p>Intermediate</p>
                </Col>
                <Col lg={2} md={12}>
                  <div className="icon">
                    <img src={FourIcon} alt="Four Icon" />
                  </div>
                  <p>Advanced</p>
                </Col>
                <Col lg={2} md={12}>
                  <div className="icon">
                    <img src={FiveIcon} alt="Five Icon" />
                  </div>
                  <p>Master</p>
                </Col>
                <Col lg={10} md={12} className="text-center">
                  <button className="btn-action w8 mt-3">View All</button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
