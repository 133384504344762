import React, { useEffect, useState } from "react";
import { LinearProgress } from "@material-ui/core";
import ClearIcon from "@mui/icons-material/Clear";
import { Container, Row, Col } from "react-bootstrap";
import { apiGet } from "../functionsAPI";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [...Array(totalPages).keys()].map((num) => num + 1);

  return (
    <div>
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="m-1 px-2 bg-primary text-white border border-primary rounded-pill"
      >
        Previous
      </button>
      {pageNumbers.map((number) => (
        <button
          className={`m-1 px-2 ${
            currentPage === number
              ? "bg-primary text-white border border-primary rounded-pill"
              : "bg-white text-primary border border-primary rounded-pill"
          }`}
          key={number}
          onClick={() => onPageChange(number)}
          disabled={currentPage === number}
        >
          {number}
        </button>
      ))}
      <button
        className="m-1  px-2  bg-primary text-white border border-primary rounded-pill"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

const About = () => {
  const [loading, setLoading] = useState(false);
  const progress = loading ? <LinearProgress /> : "";

  const [worksheetsList, setWorksheetsList] = useState([]);
  const [levelId, setLevelId] = useState("all");
  const [subjectId, setSubjectId] = useState("all");
  const [languageId, setLanguageId] = useState("all");

  const [filtersList, setFiltersList] = useState([]);

  const [levelList, setLevelList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [languageList, setLanguageList] = useState([]);

  const [level, setLevel] = useState({});
  const [subject, setSubject] = useState({});
  const [language, setLanguage] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  const totalPages = Math.ceil(worksheetsList.length / itemsPerPage);

  const currentWorksheets = worksheetsList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const getLevelList = () => {
    // const params = {
    //   is_customer: true,
    // };

    apiGet("/admin/levels/all").then((response) => {
      setLevelList(response.data);
    });
  };

  const getSubjectList = () => {
    // const params = {
    //   is_customer: true,
    // };

    apiGet("/admin/subjects/all").then((response) => {
      setSubjectList(response.data);
    });
  };

  const getLanguageList = () => {
    // const params = {
    //   is_customer: true,
    // };

    apiGet("/admin/languages/all").then((response) => {
      setLanguageList(response.data);
    });
  };

  const updateFiltersList = (filterName) => {
    setFiltersList((prevSelectedfilters) => {
      if (!prevSelectedfilters.includes(filterName)) {
        return [...prevSelectedfilters, filterName];
      }
      return prevSelectedfilters;
    });
  };

  const filterClear = (filterName) => {
    if (filtersList.length === 0) {
      setFiltersList([]);
      return getWorksheetsList();
    }
    for (const item of levelList) {
      if (item.name === filterName) {
        setLevelId("all");
        setSubject(subject ? subject.id : null);
        setLanguageId(language ? language.id : null);
      }
    }
    for (const item of subjectList) {
      if (item.name === filterName) {
        setSubjectId("all");
        setLevel(level ? level.id : null);
        setLanguageId(language ? language.id : null);
      }
    }
    for (const item of languageList) {
      if (item.name === filterName) {
        setLanguageId("all");
        setSubject(subject ? subject.id : null);
        setLevelId(level ? level.id : null);
      }
    }
  };

  const clearFilter = (filterName) => {
    setFiltersList((prevFilters) => {
      if (filterName === "Clear Filters") {
        setLanguageId("all");
        setSubjectId("all");
        setLevelId("all");
        return [];
      } else {
        const updatedFilters = prevFilters.filter((filter) => {
          return filter !== filterName;
        });
        return updatedFilters;
      }
    });
  };

  const getWorksheetsList = (limit) => {
    setLoading(true);
    const params = {
      limit: 1000000,
      level_id: levelId ? levelId : "all",
      subject_id: subjectId ? subjectId : "all",
      language_id: languageId ? languageId : "all",
      // is_customer: true,
    };

    apiGet("/admin/worksheets", params).then((response) => {
      setWorksheetsList(response.data.list);
      setLoading(false);
    });
  };
  useEffect(() => {
    getLevelList();
    getLanguageList();
    getSubjectList();
  }, []);

  useEffect(() => {
    if (filtersList.length === 0) {
      setLanguageId("all");
      setLevelId("all");
      setSubjectId("all");
    }

    getWorksheetsList(worksheetsList.length);
    // eslint-disable-next-line
  }, [levelId, subjectId, languageId, filtersList]);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      {/* <MetaTags>
                <title>{about.title}</title>
                <meta name="description" content={about.description} />
                <link rel="canonical" href={about.canonical} />
            </MetaTags> */}
      <Container>
        <div className="mb-2">{progress}</div>
        <Row>
          <Col className="my-5" xs={12} md={3}>
            <Row>
              <Col xs={4} md={12}>
                <div>
                  <h5>By Level</h5>

                  <ul>
                    {levelList.map((level) => (
                      <li
                        className="filter"
                        key={level.id}
                        onClick={() => {
                          updateFiltersList(level.name);
                          levelList
                            .filter(
                              (otherLevel) => otherLevel.name !== level.name
                            )
                            .forEach((otherLevel) =>
                              clearFilter(otherLevel.name)
                            );
                          setLevel(level);
                          setLevelId(level.id);
                        }}
                      >
                        {level.name}
                      </li>
                    ))}
                  </ul>
                </div>
                <hr className="w-75" />
              </Col>
              <Col xs={4} md={12}>
                <div>
                  <h5>By Subject</h5>
                  <ul>
                    {subjectList.map((subject) => (
                      <li
                        className="filter"
                        key={subject.id}
                        onClick={() => {
                          updateFiltersList(subject.name);
                          subjectList
                            .filter(
                              (otherSubject) =>
                                otherSubject.name !== subject.name
                            )
                            .forEach((otherSubject) =>
                              clearFilter(otherSubject.name)
                            );
                          setSubject(subject);
                          setSubjectId(subject.id);
                        }}
                      >
                        {subject.name}
                      </li>
                    ))}
                  </ul>
                </div>
                <hr className="w-75" />
              </Col>
              <Col xs={4} md={12}>
                <div>
                  <h5>By Language</h5>
                  <ul>
                    {languageList.map((language) => (
                      <li
                        className="filter"
                        key={language.id}
                        onClick={() => {
                          updateFiltersList(language.name);
                          languageList
                            .filter(
                              (otherLanguage) =>
                                otherLanguage.name !== language.name
                            )
                            .forEach((otherLanguage) =>
                              clearFilter(otherLanguage.name)
                            );
                          setLanguage(language);
                          setLanguageId(language.id);
                        }}
                      >
                        {language.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={9} className=" d-flex flex-column flex-wrap">
            <Row>
              <Col>
                <div className="d-flex flex-wrap ">
                  {filtersList.length > 1 && (
                    <div className="d-flex m-2">
                      <span
                        className="btn"
                        onClick={() => clearFilter("Clear Filters")}
                        style={{
                          backgroundColor: "#3366FF",
                          color: "white",
                          borderRadius: "3%",
                          padding: "5px 10px",
                        }}
                      >
                        Clear Filters
                      </span>
                    </div>
                  )}
                  {filtersList.map((filter, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "#3366ff",
                          borderRadius: "3%",
                          color: "white",
                          display: "inline",
                          padding: " 5px 10px",
                        }}
                      >
                        {filter}
                        <ClearIcon
                          fontSize="small"
                          color="white"
                          onClick={() => {
                            clearFilter(filter);
                            filterClear(filter);
                          }}
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                        />
                      </span>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {levelId && subjectId && languageId && (
                  <div className="d-flex mt-5 flex-wrap">
                    {currentWorksheets.length > 0 &&
                      currentWorksheets.map((item, index) => (
                        <Col sm={6} md={6} lg={3}>
                          <div
                            key={index}
                            className="sheet d-flex position-relative m-2  align-items-end justify-content-center "
                            style={{
                              border: "15px solid #FAF5ED",
                              height: "200px",
                              width: "200px",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              src={item.templates[0].path}
                              alt={item.title}
                              style={{
                                maxWidth: "100%",
                                height: "auto",
                                position: "absolute",
                                top: "0",
                                left: "0",
                                zIndex: 0,
                              }}
                            />
                            <div className=" sheet-data d-flex flex-column text-black bg-white p-2 position-relative w-100">
                              <h5>{item.title}</h5>
                              <p
                                className="desc"
                                style={{ wordWrap: "break-word" }}
                              >
                                {item.description}
                              </p>
                            </div>
                            <div className=" sheet-title d-flex flex-column text-black bg-white p-2 position-absolute">
                              <h5>{item.title}</h5>
                              <p>WorkSheet</p>
                            </div>
                          </div>
                        </Col>
                      ))}
                  </div>
                )}
                {currentWorksheets.length > 0 && (
                  <div className="d-flex justify-content-center my-5">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={onPageChange}
                    />
                  </div>
                )}
              </Col>

              <Col
                sm={12}
                className="d-flex justify-content-center align-items-center"
              >
                {currentWorksheets.length === 0 && (
                  <div className=" mt-5">
                    <h3>Items Not Found</h3>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default About;
