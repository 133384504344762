import React from "react";
import { Col, Row } from "react-bootstrap";

import HindiIcon from "../assets/images/icons/icon7.png";
import GujratiIcon from "../assets/images/icons/icon6.png";
import AlphabetIcon from "../assets/images/icons/icon4.png";

export default function MultiLangauge() {
  return (
    <>
      <section className="section-bg">
        <div className="container">
          <Row className="d-flex flex-column align-items-center">
            <Col md={6}>
              <div className="text-center mb-3 mt-5">
                <h2 className="center">Multi Langauge Support</h2>
                <p>
                  Instantly convert image documents between 100+ supported
                  languages.
                </p>
              </div>
            </Col>
            <Col md={6}>
              <Row className="text-center steps">
                <Col lg={4} md={12}>
                  <div className="icon">
                    <img src={HindiIcon} alt="Hindi Icon" />
                  </div>
                  <p>Hindi</p>
                </Col>
                <Col lg={4} md={12}>
                  <div className="icon">
                    <img src={GujratiIcon} alt="Gujrati Icon" />
                  </div>
                  <p>Gujrati</p>
                </Col>
                <Col lg={4} md={12}>
                  <div className="icon">
                    <img src={AlphabetIcon} alt="Alphabet Icon" />
                  </div>
                  <p>English</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
