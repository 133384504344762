import React, { useState, useEffect } from "react";
import { GoogleLogin } from "react-google-login";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { apiPost } from "../../functionsAPI";
import { Alert } from "@mui/material";
import { CLIENT_ID } from "../../config";

const LoginWithGoogle = (props) => {
  let history = useHistory();
  const { search } = useLocation();
  const parsed = queryString.parse(search);

  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("oocToken");
    if (token !== null) {
      var location = "/";
      if (parsed.next) {
        location += parsed.next;
      }
      history.push(location);
    }
    // eslint-disable-next-line
  }, []);

  const onSuccess = (res) => {
    setAlertType(null);
    setAlertMessage(null);
    const user = res.profileObj;
    const first_name = user.givenName;
    const last_name = user.familyName;
    const email = user.email;
    const social_id = user.googleId;
    const data = {
      first_name,
      last_name,
      email,
      social_id,
    };
    apiPost("/customer/social-sign-in", data)
      .then((res) => {
        const user = res.data;
        localStorage.setItem("oocToken", user.token);
        localStorage.setItem("user", JSON.stringify(user));
        var location = "/";
        if (parsed.next) {
          location += parsed.next;
        }
        history.push(location);
        window.location.reload();
      })
      .catch((err) => {
        localStorage.clear();
        setAlertType("error");
        setAlertMessage(err);
      });
  };

  const onFailure = (res) => {
    localStorage.clear();
    setAlertType("error");
    setAlertMessage(res.details);
  };

  return (
    <div className="text-center">
      <GoogleLogin
        clientId={CLIENT_ID}
        buttonText={"Login with Google"}
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        isSignedIn={false}
        className="google-login"
      />
      {alertType ? (
        <Alert className="mt-2" severity={alertType}>
          {alertMessage}
        </Alert>
      ) : (
        ""
      )}
    </div>
  );
};

export default LoginWithGoogle;
