import { Alert, Button, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { LoadingButton } from "@mui/lab";
import { apiGet, apiPost } from "../../functionsAPI";
import { GoogleLogout } from "react-google-login";
import { CLIENT_ID } from "../../config";

const ProfileDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const progress = loading ? <CircularProgress size={30} /> : "";

  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });
  const [isVerified, setIsVerified] = useState(false);

  const [alertTypeVerify, setAlertTypeVerify] = useState(null);
  const [alertMessageVerify, setAlertMessageVerify] = useState("");
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");

  const [currentPw, setCurrentPw] = useState("");
  const [newPw, setNewPw] = useState("");
  const [confirmPw, setConfirmPw] = useState("");

  const getProfile = () => {
    apiGet("/customer/profile")
      .then((res) => {
        setUser(res.data);
        setIsVerified(res.data.verified);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProfile();
    // eslint-disable-next-line
  }, []);

  const handleVerify = () => {
    apiPost("/customer/verify/resend")
      .then((res) => {
        setAlertTypeVerify("success");
        setAlertMessageVerify(
          "Verification mail sent. Please check your inbox"
        );
      })
      .catch((err) => {
        setAlertTypeVerify("error");
        setAlertMessageVerify(err);
      });
  };

  const handleChangePw = (e) => {
    e.preventDefault();
    setLoading(true);
    setAlertType(null);
    setAlertMessage("");
    if (newPw !== confirmPw) {
      setAlertType("error");
      setAlertMessage("Passwords are not same");
      setLoading(false);
    } else {
      const data = {
        old_password: currentPw,
        new_password: newPw,
      };
      apiPost("/customer/change-password", data)
        .then((res) => {
          setAlertType("success");
          setAlertMessage("Password changed successfully");
          setCurrentPw("");
          setNewPw("");
          setConfirmPw("");
          setLoading(false);
        })
        .catch((err) => {
          setAlertType("error");
          setAlertMessage(err);
          setLoading(false);
        });
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    props.history.push("/");
    window.location.reload();
  };

  return (
    <>
      <h3>General Profile Settings</h3>
      <table>
        <tr>
          <td>Name</td>
          <th>
            {user.first_name} {user.last_name}
          </th>
        </tr>
        <tr>
          <td>Email</td>
          <th>
            {user.email} <br />{" "}
            {isVerified ? (
              ""
            ) : (
              <span className="verify">
                (not verified,{" "}
                <span onClick={handleVerify}>Click to Verify</span>)
              </span>
            )}
          </th>
        </tr>
        {alertTypeVerify ? (
          <tr>
            <td colSpan={2}>
              <Alert severity={alertTypeVerify}>{alertMessageVerify}</Alert>
            </td>
          </tr>
        ) : (
          ""
        )}
      </table>
      <GoogleLogout
        clientId={CLIENT_ID}
        render={(renderProps) => (
          <Button
            variant="outlined"
            color="error"
            disabled={renderProps.disabled}
            onClick={renderProps.onClick}
          >
            Log Out
          </Button>
        )}
        buttonText="Logout"
        onLogoutSuccess={handleLogout}
      />
      <br />
      <br />
      <br />
      <h3>Change Password</h3>
      <form onSubmit={handleChangePw}>
        <Row>
          <Col md={4}>
            <input
              type="password"
              required
              value={currentPw}
              className="form-control"
              onChange={(e) => setCurrentPw(e.target.value)}
              placeholder="Current Password *"
            />
          </Col>
          <Col md={4}>
            <input
              type="password"
              required
              value={newPw}
              className="form-control"
              onChange={(e) => setNewPw(e.target.value)}
              placeholder="New Password *"
            />
          </Col>
          <Col md={4}>
            <input
              type="password"
              required
              value={confirmPw}
              className="form-control"
              onChange={(e) => setConfirmPw(e.target.value)}
              placeholder="Re-type New Password *"
            />
          </Col>
        </Row>
        <LoadingButton
          type="submit"
          loading={loading}
          className="mt-4"
          loadingIndicator="Changing..."
          variant="outlined"
        >
          Change
        </LoadingButton>
      </form>
      <p className="mt-3">
        {progress}
        {alertType ? <Alert severity={alertType}>{alertMessage}</Alert> : ""}
      </p>
    </>
  );
};

export default ProfileDetails;
