import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { apiGetURL } from '../functionsAPI'
import selectedPlanContext from "../selectedPlanContext";

const priceInr = {
    symbol: '$',
    amount: '99',
}

const priceOther = {
    symbol: '$',
    amount: '30',
}

export default function EnterprisePlan(props) {
    const [symbol, setSymbol] = useState('$')
    const [amount, setAmount] = useState('99')
    const contextPlan = useContext(selectedPlanContext)
    const getCountry = () => {
        apiGetURL('https://ipapi.co/json/')
            .then((res) => {
                console.log(res);
                const countryCode = res.data.country_code
                if (countryCode === 'IN') {
                    setSymbol(priceInr.symbol)
                    setAmount(priceInr.amount)
                }
                else {
                    setSymbol(priceOther.symbol)
                    setAmount(priceOther.amount)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    useEffect(() => {
        getCountry()
        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [])


    return (
        <>
            <div className="type-1">
                <h3>Enterprise</h3>
                <div className="price">
                    <span className="currency">{symbol}</span>
                    <span className="amount">{amount}</span>
                </div>
                <p className='validity'>Per Month</p>
                <div className="features">
                    <p>Unlimited Downloads</p>
                    <p>1 Year Validity</p>
                </div>
                {props.displayButton ? (
                    <Link to="/sign-in?next=subscribe" onClick={()=>contextPlan.update('Enterprise')} className="btn-action">Purchase Now</Link> // to={{pathname:'/sign-in?next=subscribe', state:{planName:'Enterprise'}}}
                ) : ''}
            </div>
        </>
    )
}

EnterprisePlan.defaultProps = {
    displayButton: true
}