import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import { Link, useLocation } from "react-router-dom";
import { menu } from "../Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Logo from "../assets/images/logo/top-5-logo.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const Header = () => {
  let location = useLocation().pathname;
  const [anchorEl, setAnchorEl] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [sideMenu, setSideMenu] = useState(false);

  const handleClick = (event) => {
    console.log("%%%%%%%");
    setAnchorEl(true);
  };
  const handleClose = () => {
    setAnchorEl(false);
  };

  function checkUrl(url) {
    if (location === url) {
      return "active";
    } else {
      return null;
    }
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setSideMenu({ sideMenu, left: open });
  };

  useEffect(() => {
    const token = localStorage.getItem("oocToken");
    if (token !== null) {
      setLoggedIn(true);
    }
  }, []);

  return (
    <div id="header">
      <Container>
        {/* Mobile Menu */}
        <SwipeableDrawer
          open={sideMenu["left"]}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <Box
            sx={{ width: 300 }}
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <div className="mobile-logo z-4">
              <img src={Logo} alt="Top 5 Logo" width="200px" />
            </div>
            <Divider />
            <List>
              {menu.map((menuItem, index) => (
                <div key={index} onClick={() => {}}>
                  {menuItem === "Subjects" ? (
                    <div>
                      <ListItem
                        key={index}
                        onClick={() => {
                          console.log("&&&&&&&&&&&&&");
                        }}
                      >
                        <ListItemText
                          onClick={() => {
                            console.log("&&&&&&&&&&&&&");
                          }}
                          primary={menuItem.label}
                        />
                      </ListItem>
                    </div>
                  ) : (
                    <ListItem button key={index}>
                      <Link to={menuItem.path}>
                        <ListItemText
                          primary={menuItem.label}
                          onClick={() => {}}
                        />
                      </Link>
                    </ListItem>
                  )}
                </div>
              ))}
            </List>
          </Box>
        </SwipeableDrawer>
        {/* END: Mobile Menu */}
        <Link to="/" className="mt-3">
          <img src={Logo} alt="Top 5 Logo" width="200px" />
        </Link>
        <Button
          onClick={toggleDrawer(true)}
          className="d-block d-lg-none menuicon"
        >
          <MenuIcon />
        </Button>
        <nav id="navbar" className="navbar right d-none d-lg-block">
          <ul>
            {menu.map((menu, index) => {
              return (
                <li key={index} className={checkUrl(menu.path)}>
                  <Link to={menu.path}>{menu.label}</Link>
                </li>
              );
            })}
            <li>
              {loggedIn ? (
                <Link to="/profile" className="btn-auth">
                  My Profile
                </Link>
              ) : (
                <Link to="/sign-in" className="btn-auth">
                  Login/Register
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </Container>
    </div>
  );
};

export default Header;
