import React from "react";
import { homepage } from "../MetaTags";
import MetaTags from "react-meta-tags";
import PlansAndPricing from "../components/PlansAndPricing";
import HowItWorks from "../components/HowItWorks";
import Subjects from "../components/Subjects";
import MultiLangauge from "../components/Multilangauge";
import Features from "../components/Features";
import OurApi from "../components/OurApi";
import HomeImage from "../assets/images/hero_image.png";
import MultiLevelSupport from "../components/MultilevelSupport";

const Homepage = () => {
  return (
    <>
      <MetaTags>
        <title>{homepage.title}</title>
        <meta name="description" content={homepage.description} />
        <link rel="canonical" href={homepage.canonical} />
      </MetaTags>
      <div className="main">
        <div className="child">
          <div className="container">
            <h1>Explore an Endless Universe Knowledge</h1>
            <p>
              Embark on a Endless Journy Through a Universe of Knowledge, Where
              Curiosity Knows No Bounds. Discover The Vast Wonders That Awaits
              You.
            </p>
            <img
              src={HomeImage}
              alt="App Screen"
              className="img-fluid app-screen mt-3"
            />
          </div>
        </div>
      </div>

      <HowItWorks />
      <img
        src="./assets/images/logo/top-5-logo.png"
        alt=""
        width="200px"
        className="img-fluid"
      />

      <Features />
      <PlansAndPricing />
      <MultiLangauge />
      <Subjects />
      <MultiLevelSupport />
      <OurApi />
    </>
  );
};

export default Homepage;
