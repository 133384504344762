import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Login from '../assets/images/icons/icon1.png'
import Download from "../assets/images/icons/icon2.png"
import Emoji from "../assets/images/icons/icon3.png"
// import FileUploadIcon from '@mui/icons-material/FileUpload';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

export default function HowItWorks() {
    return (
        <>
            <section className='section-bg how-it-works'>
                <div className="container">
                    <Row>
                        <Col md={4} sm={12}>
                            <h2>How Does It Works</h2>
                            <p>First, upload the image file to our OCR Converter. Then the OCR Converter will instantly scan the image file. After the scanning process is done, you will get your extracted text from your image file.</p>
                        </Col>
                        <Col md={8}>
                            <Row className='text-center steps'>
                                <Col lg={4} md={12}>
                                    <div className="icon">
                                            <img src={Login} alt='Login Icon' />
                                    </div>
                                    <p>Upload your file to our online OCR converter.</p>
                                </Col>
                                <Col lg={4} md={12}>
                                     <div className="icon">
                                            <img src={Download} alt='Download Icon' />
                                    </div>
                                    <p>OCR converter will instantly start the scanning process.</p>
                                </Col>
                                <Col lg={4} md={12}>
                                    <div className="icon">
                                            <img src={Emoji}  alt="Emoji Icon"  />
                                    </div>
                                    <p>Wait for the converter to finish and Easily Copy The Text</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    )
}
