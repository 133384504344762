import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import RoomIcon from "@mui/icons-material/Room";
import EmailIcon from "@mui/icons-material/Email";
import { Alert, CircularProgress, Grid } from "@mui/material";
import contactImage from "../assets/images/contact.svg";
import { ContactApiUrl } from "../config";
import { LoadingButton } from "@mui/lab";
// Library
import axios from "axios";

const ContactComponent = (props) => {
  const history = useHistory();

  const to_email = "info@arkayapps.com";
  const cc = "arkayappsseo@gmail.com";
  const title = "Website Contact Form";
  const subject = "Contact Form - Top 5 Learning";

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);

  const progress = loading ? <CircularProgress size={30} /> : "";
  const template = `<!DOCTYPE html>
    <html lang="en">
    
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Sundrop Solar</title>
        <style>
            body {
                line-height: 1.4;
                font-family: sans-serif;
                background-color: #f6f6f6;
            }
    
            p {
                margin: 0;
                margin-bottom: 15px;
                font-size: 14px;
                font-weight: normal;
                font-family: sans-serif;
            }
    
            hr {
                border: 0;
                border-bottom: 1px solid #b9b9b9;
                margin: 10px 0;
            }
    
            .logo {
                margin-top: 1rem;
                margin-bottom: 1rem;
                width:200px;
                height:auto;
            }
    
            .text-center {
                text-align: center !important;
            }
    
            .content {
                Margin: 0 auto;
                display: block;
                max-width: 580px;
                padding: 0 10px 0 10px;
                box-sizing: border-box;
            }
    
            .innerwrap {
                box-sizing: border-box;
                padding: 20px 20px 10px 20px;
                background: #f2f2f2;
            }
    
            .card-header {
                background: #3366FF;
                color: #fff;
                text-align: center;
                padding: 20px;
                font-size: 16px;
            }
    
            .card-header p {
                color: #fff;
                text-align: center;
                font-size: 16px;
                margin-bottom: unset;
            }
    
            .card-body {
                background: #ffffff;
                border-radius: 3px;
                width: 100%;
                box-sizing: border-box;
                padding: 20px;
                border-bottom: 3px solid #A09ED6;
            }
    
            .card-body p strong {
                color: #333;
            }
    
            .card-body p strong.heading {
                color: #A09ED6;
            }
            .blue {
               color: #3366FF;
            }
    
            .copyright {
                color: #999999;
                font-size: 12px;
                text-align: center;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
        </style>
    </head>
    
    <body>
        <div class="content">
            <div class="text-center">
              <h2 class="blue">Top 5 Learning</h2>
            </div>
            <div class="card-header">
                <p>You Received Message From Contact Us !</p>
            </div>
            <div class="card-body">
                <p><strong>Name : </strong>${name}</p>
                <p><strong>Email: </strong>${email}</p>
                <hr>
                <p><strong>Message : </strong>${message}</p>
            </div>
            <div class="copyright">
                <p>© 2023 Top 5 Learning
                All rights reserved.</p>
            </div>
        </div>
    </body>
    
    </html>`;

  const onSubmit = (e) => {
    e.preventDefault();
    setError(false);
    setLoading(true);

    if (name === null) {
      setError(<Alert variant={"danger"}>Name Required</Alert>);
      setLoading(false);
    } else if (email === null || email.indexOf(".com") === -1) {
      setError(
        <Alert variant={"danger"}>Please Enter Valid Email Required</Alert>
      );
      setLoading(false);
    } else if (message === null) {
      setError(<Alert variant={"danger"}>Message Required</Alert>);
      setLoading(false);
    } else {
      const data = {
        title: title,
        subject: subject,
        to_email: to_email,
        cc: cc,
        template: template,
      };
      const headerConfig = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      // const url = 'http://192.168.0.106:8090/send-mail';
      axios
        .post(ContactApiUrl, data, headerConfig)
        .then((res) => {
          setName("");
          setEmail("");
          setMessage("");
          setLoading(false);
          history.push("/thank-you");
        })
        .catch((err) => {
          console.log(err);
          setError(err);
          setLoading(false);
        });
    }
  };
  return (
    <>
      <section id="contact" className="container">
        <div className="card">
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} sm={5}>
              <div className="text-center">
                <img
                  src={contactImage}
                  alt="Contact Us"
                  className="img-fluid"
                />
              </div>
              <div className="info">
                <RoomIcon />
                <p>
                  210, Pramukh Square, <br />
                  Opp. Regenta Resort, <br />
                  Airport Ring Road, <br /> Bhuj – Kutch 370001{" "}
                </p>
                <EmailIcon />
                <p>
                  <a href="mailto:info@arkayapps.com">info@arkayapps.com</a>
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <h2>Get In Touch</h2>
              <p>We are here for you! How can we help you?</p>
              <form onSubmit={onSubmit}>
                <input
                  type="text"
                  required
                  className="form-control"
                  value={name}
                  minLength="3"
                  maxLength="50"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter your name"
                />
                <input
                  type="email"
                  required
                  className="form-control"
                  value={email}
                  minLength="3"
                  maxLength="50"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                />
                <textarea
                  className="form-control"
                  required
                  rows="5"
                  value={message}
                  placeholder="Enter your message"
                  minLength="5"
                  maxLength="255"
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                <LoadingButton
                  type="submit"
                  loading={loading}
                  className="mt-4"
                  fullWidth
                  loadingIndicator="Sending..."
                  variant="contained"
                >
                  Submit
                </LoadingButton>
                <p className="mt-5 text-center">{progress}</p>
              </form>
              {error ? <Alert severity="error">{error}</Alert> : ""}
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
};

export default ContactComponent;
