import React from "react";
import { Col, Row } from "react-bootstrap";
import RoomIcon from "@mui/icons-material/Room";
import EmailIcon from "@mui/icons-material/Email";
import { Link } from "react-router-dom";
import CookieBanner from "../pages/auth/CookieBanner";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Logo from "../assets/images/logo/top-5-logo-dark.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <section id="footer">
      <div className="container">
        <Row>
          <Col lg={3} md={6} sm={12} className="colm-1">
            <img src={Logo} alt="Top 5 Logo" width="100%" />
            <p>
              Discover, create, and Excel with our educational worksheets at Top
              5 Learning, nurturing young minds for a brighter future.
            </p>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <h5>Important Links</h5>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about-us">Worksheets</Link>
              </li>
              <li>
                <Link to="/plans-pricing">Pricing Plans</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </Col>
          <Col lg={3} md={6} sm={12} className="contact">
            <h5>Get In Touch</h5>
            <RoomIcon />
            <p>
              Arkay Apps Pvt. Ltd.
              <br /> 210, Pramukh Square, <br />
              Opp. Regenta Resort, <br />
              Airport Ring Road, <br /> Bhuj – Kutch 370001{" "}
            </p>
            <LocalPhoneIcon />
            <p>+91 &nbsp;932-709-3554</p>
            <EmailIcon />
            <a href="mailto:info@arkayapps.com">info@arkayapps.com</a>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <h5>Social Media</h5>
            <ul>
              <li>
                <Link href="/">
                  <InstagramIcon /> &nbsp;@top_five_learning
                </Link>
              </li>
              <li>
                <Link href="/">
                  <FacebookOutlinedIcon /> &nbsp; /top 5 learning
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
        <hr />
        <div className="copyright">
          <p>
            © Copyright 2020-{currentYear} - Top Five Learing. All Rights
            Reserved. Website Designed And Developed by Arkay Apps Pvt. Ltd
          </p>
        </div>
      </div>
      <CookieBanner />
    </section>
  );
};

export default Footer;
