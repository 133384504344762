import React, { useEffect, useCallback, useState } from "react";
import languages from "../languages";
import { Col, Row } from "react-bootstrap";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import { TabsList } from "@mui/base/TabsList";
import { TabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab, tabClasses } from "@mui/base/Tab";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Alert, Button, CircularProgress, Snackbar } from "@mui/material";
import { apiPost } from "../functionsAPI";
import LoadingButton from "@mui/lab/LoadingButton";
import { formatBytes } from "../functions";
import validator from "validator";
import { useDropzone } from "react-dropzone";
import { apiGet } from "../functionsAPI";

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const StyledTab = styled(Tab)`
  font-family: IBM Plex Sans, sans-serif;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    border-radius: 3px;
    outline: 2px solid ${blue[200]};
    outline-offset: 2px;
  }

  &.${tabClasses.selected} {
    background-color: ${blue[50]};
    color: ${blue[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const StyledTabsList = styled(TabsList)`
  min-width: 320px;
  background-color: ${blue[500]};
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

export default function OcrWidget(props) {
  const [loading, setLoading] = useState(false);
  const progress = loading ? <CircularProgress size={30} /> : "";
  // helpers
  const [key, setKey] = useState(0);
  const [method, setMethod] = useState(0);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [type, setType] = useState(null);
  const [image, setImage] = useState(null);
  const [base64, setBase64] = useState(null);
  const [url, setURL] = useState("");
  const [urlP, setURLP] = useState("");
  const [language, setLanguage] = useState(
    props.value ? props.value : languages[22]
  );

  const [result, setResult] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [warning, setWarning] = useState("");

  // meta
  const [meta, setMeta] = useState(0);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handelUrlChange = (e) => {
    let value = e.target.value;
    setURL(value);
    if (validator.isURL(value)) {
      setIsValidUrl(true);
      setURLP(value);
    } else {
      setIsValidUrl(false);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setOpenError(false);
  };

  //Get the user subscription details
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [userPlan, setUserPlan] = useState({
    plan: "",
  });

  const getSubscriptionDetails = () => {
    apiGet("/user-subscription")
      .then((res) => {
        setUserPlan(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("oocToken");
    if (token !== null) {
      setIsUserLoggedIn(true);
    }
    getSubscriptionDetails();
    // eslint-disable-next-line
  }, []);

  // Convert Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccess(false);
    setResult(false);
    setError(false);
    setLoading(true);
    let data = null;
    let apiURL = null;

    if (isUserLoggedIn) {
      if (userPlan.plan === "Free" && meta.size > 5 * 1024 * 1024) {
        setError("File size exceeds 5 MB limit");
        setLoading(false);
        return;
      } else if (
        userPlan.plan === "Professional" &&
        meta.size > 20 * 1024 * 1024
      ) {
        setError("File size exceeds 20 MB limit");
        setLoading(false);
        return;
      } else if (userPlan.plan === "Enterprise") {
        setLoading(true);
      }
    } else {
      if (meta.size > 5 * 1024 * 1024) {
        setError("File size exceeds 5 MB limit");
        setLoading(false);
        return;
      } else {
        setLoading(true);
      }
    }

    if (method === 0) {
      if (base64 && language) {
        data = {
          base64: base64,
          language: props.value ? props.value.value : language.value,
        };
        apiURL = "/image";
        setType(0);
      } else {
        setError("Please select an image and a language");
        setLoading(false);
      }
    } else {
      if (url && language) {
        data = {
          url,
          language: props.value ? props.value.value : language.value,
        };
        apiURL = "/url";
        setType(1);
      } else {
        setError("Please enter URL and a language");
        setLoading(false);
      }
    }
    if (data) {
      apiPost(apiURL, data)
        .then((res) => {
          const _key = key + 1;
          setKey(_key);
          setResult(res.data.text);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError(err);
          setLoading(false);
        });
    }
  };

  const handleCopyText = () => {
    navigator.clipboard.writeText(result);
    setSuccess("Text copied to clipboard");
  };
  const handleSaveAsText = () => {
    let data = null;
    let apiURL = null;
    let fileName = null;
    if (method === 0) {
      data = {
        base64: base64,
        language: props.value ? props.value.value : language.value,
      };
      apiURL = "/image-text";
      fileName = meta.name.substring(0, meta.name.lastIndexOf("."));
    } else {
      data = {
        url,
        language: props.value ? props.value.value : language.value,
      };
      apiURL = "/url-text";
      const parts = urlP.split("/");
      const Name = parts[parts.length - 1];
      fileName = Name.substring(0, Name.lastIndexOf("."));
    }
    if (data) {
      apiPost(apiURL, data, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${fileName + ".txt"}`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          setOpen(true);
          setSuccess("Text File Downloaded");
        })
        .catch((err) => {
          console.log(err);
          setWarning(err);
          setLoading(false);
          setOpenError(true);
          setError("Error in Downloading");
        });
      setSuccess("");
      setWarning("");
      setError("");
    }
  };

  const handleSaveAsWord = () => {
    let data = null;
    let apiURL = null;
    let fileName = null;
    if (method === 0) {
      data = {
        base64: base64,
        language: props.value ? props.value.value : language.value,
      };
      apiURL = "/image-word";
      fileName = meta.name.substring(0, meta.name.lastIndexOf("."));
    } else {
      data = {
        url,
        language: props.value ? props.value.value : language.value,
      };
      apiURL = "/url-word";
      const parts = urlP.split("/");
      const Name = parts[parts.length - 1];
      fileName = Name.substring(0, Name.lastIndexOf("."));
    }
    if (data) {
      apiPost(apiURL, data, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${fileName + ".docx"}`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          setOpen(true);
          setSuccess("Word File Downloaded");
        })
        .catch((err) => {
          console.log(err);
          setWarning(err);
          setLoading(false);
          setOpenError(true);
          setError("Error in Downloading");
        });
      setSuccess("");
      setWarning("");
      setError("");
    }
  };

  const handleRemoveImage = () => {
    setImage(null);
    setBase64(null);
    setURL("");
    setResult("");
    setError(false);
    setMeta(null);
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    // Do something with the files
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setMeta(file);
      var src = URL.createObjectURL(file);
      setImage(src);
      const base64 = await convertBase64(file);
      setBase64(base64);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 1,
  });

  return (
    <div>
      <Row className="justify-content-center">
        <Col md={8} className="card">
          <Tabs
            defaultValue={0}
            onChange={(e, val) => {
              setMethod(val);
            }}
          >
            <StyledTabsList>
              <StyledTab>File Upload</StyledTab>
              <StyledTab>Paste URL</StyledTab>
            </StyledTabsList>
            <StyledTabPanel value={0}>
              <Row className="justify-content-center">
                <Col md={8}>
                  <div {...getRootProps()} className="drop-area">
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <>
                        <UploadFileIcon /> Drop the files here ...
                      </>
                    ) : (
                      <>
                        <UploadFileIcon /> Select or Drag file here
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              {image ? (
                <Row className="justify-content-center">
                  <Col md={4}>
                    <img
                      src={image}
                      className="img-fluid mb-4 img-preview"
                      alt="selected"
                    />
                  </Col>
                  <Col md={4}>
                    File Name: {meta.name} <br />
                    Size: {formatBytes(meta.size)} <br />
                    <br />
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={handleRemoveImage}
                    >
                      Remove
                    </Button>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </StyledTabPanel>
            <StyledTabPanel value={1}>
              <Row className="justify-content-center">
                <Col md={8}>
                  <TextField
                    error={!isValidUrl}
                    required
                    fullWidth
                    value={url}
                    onChange={handelUrlChange}
                    className="mt-4 mb-4"
                    label="Enter URL"
                    helperText={isValidUrl ? "" : "Please enter a valid URL"}
                    variant="outlined"
                  />
                  {url && isValidUrl && (
                    <img
                      src={url}
                      className="img-fluid mb-4 img-preview"
                      alt="selected"
                    />
                  )}
                </Col>
              </Row>
            </StyledTabPanel>
            <Row className="justify-content-center language">
              <Col md={8}>
                {props.value ? (
                  <p className="text-center">
                    Selected Language: {props.value.name}
                  </p>
                ) : (
                  <Autocomplete
                    options={languages}
                    value={language}
                    className="mt-4"
                    getOptionLabel={(option) => option.name}
                    onChange={(e, val) => setLanguage(val)}
                    renderInput={(params) => (
                      <TextField {...params} required label="Language" />
                    )}
                  />
                )}
              </Col>
            </Row>
            <div className="text-center">
              <LoadingButton
                onClick={handleSubmit}
                loading={loading}
                disabled={method === 1 && !isValidUrl}
                loadingIndicator="Converting..."
                variant="contained"
                className="btn-convert"
              >
                Convert Now
              </LoadingButton>
              <p className="mt-5">{progress}</p>
            </div>
            {result ? (
              <div className="result">
                <TextField
                  fullWidth
                  multiline
                  value={result}
                  label="Result"
                  onChange={(e) => {
                    setResult(e.target.value);
                  }}
                  variant="outlined"
                />
                <div className="buttons-pos">
                  <Button
                    variant="contained"
                    color="primary"
                    className="mt-4"
                    size="medium"
                    onClick={handleCopyText}
                  >
                    Copy Text
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="mt-4 ml-4"
                    size="medium"
                    onClick={handleSaveAsWord}
                  >
                    Save As Word File
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="mt-4 ml-4"
                    size="medium"
                    onClick={handleSaveAsText}
                  >
                    Save As Text File
                  </Button>
                </div>
                {success ? (
                  <Snackbar
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                  >
                    <Alert
                      onClose={handleClose}
                      severity="success"
                      className="mt-4"
                    >
                      {success}
                    </Alert>
                  </Snackbar>
                ) : (
                  ""
                )}
                {success ? (
                  <Alert severity="success" className="mt-4">
                    {success}
                  </Alert>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {error ? (
              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openError}
                autoHideDuration={3000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="error">
                  {error}
                </Alert>
              </Snackbar>
            ) : (
              ""
            )}
            {error ? (
              <Alert severity="error" className="mt-4">
                {error}
              </Alert>
            ) : (
              ""
            )}
            {/* { warning ? (
                            <Alert severity="warning" className='mt-2'>{warning}</Alert>
                        ):''} */}
          </Tabs>
        </Col>
      </Row>
    </div>
  );
}

OcrWidget.defaultProps = {
  value: null,
};
