import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import { Col, Container, Row } from "react-bootstrap";
import AppFeatures from "../assets/images/graphic3.png";

export default function Features() {
  return (
    <div>
      <section>
        <Container>
          <Row className="features">
            <Col lg={8} md={6} className="order-md-0 order-sm-1">
              <h2>Features</h2>
              <div className="feature steps">
                <div className="icon">
                  <CheckIcon />
                </div>
                <h3>
                  Choose from a diverse array of languages to cater to a globel
                  audience.
                </h3>
              </div>
              <div className="feature">
                <div className="icon">
                  <CheckIcon />
                </div>
                <h3>
                  Choose from a broad range of subjects for a comprehensive
                  learning.
                </h3>
              </div>
              <div className="feature">
                <div className="icon">
                  <CheckIcon />
                </div>
                <h3>
                  Access educcational materials instantly with convenient
                  download options.
                </h3>
              </div>
              <div className="feature">
                <div className="icon">
                  <CheckIcon />
                </div>
                <h3>
                  Print worksheets effortlessly at home for flexible learning.
                </h3>
              </div>
              <div className="feature">
                <div className="icon">
                  <CheckIcon />
                </div>
                <h3>
                  Engage in hands-on activities fostering creativity and
                  critical thinking.
                </h3>
              </div>
              <div className="feature">
                <div className="icon">
                  <CheckIcon />
                </div>
                <h3>
                  Navigate seamlessly with an intuitive design for both parent
                  and students.
                </h3>
              </div>
            </Col>
            <Col md={6} lg={4} className="my-auto order-md-1 order-sm-0">
              <img src={AppFeatures} alt="App Features" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
