import { LoadingButton } from "@mui/lab";
import LoginWithGoogle from "./LoginWithGoogle";
import { Alert, Chip, CircularProgress, Divider } from "@mui/material";
import React, { useState } from "react";
import { trim } from "../../functions";
import { apiPost } from "../../functionsAPI";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export default function SignIn(props) {
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const [loading, setLoading] = useState(false);
  const progress = loading ? <CircularProgress size={30} /> : "";
  const [error, setError] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    const data = {
      email: trim(email),
      password: trim(password),
    };
    apiPost("/customer/sign-in", data)
      .then((res) => {
        const user = res.data;
        localStorage.setItem("oocToken", user.token);
        localStorage.setItem("user", JSON.stringify(user));
        setLoading(false);
        var location = "/";
        if (parsed.next) {
          location += parsed.next;
        }
        props.history.push(location);
        window.location.reload();
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="card">
        <h2>Login</h2>
        <form onSubmit={onLogin}>
          <input
            type="email"
            required
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter Email"
          />
          <input
            type="password"
            required
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter Password"
          />
          <div className="text-end mt-2">
            <Link to="forgot-password" className="forgot">
              Forgot password?
            </Link>
          </div>
          <LoadingButton
            type="submit"
            loading={loading}
            className="mt-2"
            fullWidth
            loadingIndicator="Authenticating..."
            variant="contained"
          >
            Login
          </LoadingButton>
          <p className="mt-3 text-center">{progress}</p>
        </form>
        <Divider className="my-4">
          <Chip label="OR" variant="outlined" />
        </Divider>
        <LoginWithGoogle />
        {error ? <Alert severity="error">{error}</Alert> : ""}
      </div>
    </>
  );
}
