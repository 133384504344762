import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SubjectImg from "../assets/images/graphic1.png";
import CalculatorIcon from "../assets/images/icons/icon8.png";
import ScienceIcon from "../assets/images/icons/icon9.png";
import ArtIcon from "../assets/images/icons/icon11.png";
import Alphabet from "../assets/images/icons/icon4.png";
import SocialScienceIcon from "../assets/images/icons/icon10.png";

export default function Subjects() {
  return (
    <div>
      <section className="section">
        <Container>
          <Row className="subjects">
            <Col md={6}>
              <img src={SubjectImg} alt="Subject Icons" className="img-fluid" />
            </Col>
            <Col md={6}>
              <div className="text-center mb-3 mt-5">
                <h2 className="center">Multiple Subjects Available</h2>
                <p>
                  Instantly convert image documents between 100+ supported
                  languages.
                </p>
              </div>
              <Row className="text-center steps mt-5">
                <Col lg={4} md={12}>
                  <div className="icon">
                    <img src={CalculatorIcon} alt="Calculator Icon" />
                  </div>
                  <p>Maths</p>
                </Col>
                <Col lg={4} md={12}>
                  <div className="icon">
                    <img src={ScienceIcon} alt="Science Icon" />
                  </div>
                  <p>Science</p>
                </Col>
                <Col lg={4} md={12}>
                  <div className="icon">
                    <img src={ArtIcon} alt="Art And Craft Icon" />
                  </div>
                  <p>Art And Craft</p>
                </Col>
                <Col lg={4} md={12}>
                  <div className="icon">
                    <img src={Alphabet} alt="Alphabet Icon" />
                  </div>
                  <p>English</p>
                </Col>
                <Col lg={4} md={12}>
                  <div className="icon">
                    <img src={SocialScienceIcon} alt="Social Science Icon" />
                  </div>
                  <p>Social Science</p>
                </Col>
                <Col lg={12} md={12}>
                  <p className="btn-action w8 mt-3">View All</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
