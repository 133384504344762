import React, { useEffect } from 'react'
// import MetaTags from 'react-meta-tags';
import { Container } from 'react-bootstrap';

const ThankYou = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [])
    return (
        <div>
            {/* <MetaTags>
                <title>{thankYou.title}</title>
                <meta name="description" content={thankYou.description} />
                <link rel="canonical" href={thankYou.canonical} />
            </MetaTags> */}
            <div className="breadcrumbs text-center pb-5 pt-5">
                <Container>
                    <h1>Thank You</h1>
                </Container>
            </div>
            <section>
                <Container className="section">
                        <div className="text-center mt-5 pb-5">
                            <h2>Message <span>Sent Successfully!</span></h2>
                            <p>We have recieved your mail and we will contact you shortly.</p>
                            <p>Click to go on <a href="/">Homepage</a></p>
                        </div>
                </Container>
            </section>
        </div>
    )
}

export default ThankYou
