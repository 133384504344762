import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { contactUs } from "../../MetaTags";
import { Alert, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { apiPost } from "../../functionsAPI";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const progress = loading ? <CircularProgress size={30} /> : "";

  const [otpSent, setOtpSent] = useState(false);
  const [success, setSuccess] = useState(false);

  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confNewPassword, setConfNewPassword] = useState("");

  const sendOtp = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      email,
    };
    apiPost("/customer/forgot-password", data)
      .then((res) => {
        setAlertType("success");
        setAlertMessage("OTP sent to your email");
        setOtpSent(true);
        setLoading(false);
      })
      .catch((err) => {
        setAlertType("error");
        setAlertMessage(err);
        setLoading(false);
      });
  };

  const resetPassword = (e) => {
    e.preventDefault();
    setLoading(true);
    if (newPassword !== confNewPassword) {
      setAlertType("error");
      setAlertMessage("Passwords are not same");
    } else {
      const data = {
        email,
        otp,
        password: newPassword,
      };
      apiPost("/customer/confirm-forgot-password", data)
        .then((res) => {
          setAlertType("success");
          setAlertMessage("Password changed successfully. You can Login Now");
          setOtpSent(true);
          setSuccess(true);
          setLoading(false);
        })
        .catch((err) => {
          setAlertType("error");
          setAlertMessage(err);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <MetaTags>
        <title>{contactUs.title}</title>
        <meta name="description" content={contactUs.description} />
        <link rel="canonical" href={contactUs.canonical} />
      </MetaTags>
      <section className="breadcrumbs">
        <div className="inner">
          <div className="container">
            <h1>Forgot Password?</h1>
            <p>No need to worry, we are here for you</p>
          </div>
        </div>
      </section>
      <section id="forgot-pw">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              {success ? (
                <Alert className="mb-4" severity={"success"}>
                  Password changed successfully. Click here to{" "}
                  <Link to="sign-in">Login Now</Link>
                </Alert>
              ) : otpSent ? (
                <div className="card">
                  {alertType ? (
                    <Alert className="mb-4" severity={alertType}>
                      {alertMessage}
                    </Alert>
                  ) : (
                    ""
                  )}
                  <h6>Enter OTP And Create New Password</h6>
                  <form onSubmit={resetPassword}>
                    <input
                      type="text"
                      required
                      className="form-control"
                      value={otp}
                      minLength="6"
                      maxLength="6"
                      title="Numbers only"
                      pattern="[0-9]{6}"
                      onChange={(e) => setOtp(e.target.value)}
                      placeholder="Enter OTP"
                    />
                    <input
                      type="password"
                      required
                      className="form-control"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      placeholder="New Password"
                    />
                    <input
                      type="password"
                      required
                      className="form-control"
                      value={confNewPassword}
                      onChange={(e) => setConfNewPassword(e.target.value)}
                      placeholder="Confirm New Password"
                    />
                    <LoadingButton
                      type="submit"
                      loading={loading}
                      className="mt-4"
                      fullWidth
                      loadingIndicator="Sending..."
                      variant="contained"
                    >
                      Reset Password
                    </LoadingButton>
                    <p className="mt-5 text-center">{progress}</p>
                  </form>
                </div>
              ) : (
                <div className="card">
                  <h6>Enter your email to reset your password</h6>
                  <form onSubmit={sendOtp}>
                    <input
                      type="email"
                      required
                      className="form-control"
                      value={email}
                      minLength="3"
                      maxLength="50"
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                    />
                    <LoadingButton
                      type="submit"
                      loading={loading}
                      className="mt-4"
                      fullWidth
                      loadingIndicator="Sending..."
                      variant="contained"
                    >
                      Submit
                    </LoadingButton>
                    <p className="mt-5 text-center">{progress}</p>
                  </form>
                  {alertType ? (
                    <Alert className="mb-4" severity={alertType}>
                      {alertMessage}
                    </Alert>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ForgotPassword;
