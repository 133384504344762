import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function OurApi() {
  return (
    <>
      <section className="section-bg faq">
        <div className="container faq-section">
          <div className="d-flex flex-sm-column flex-lg-row justify-content-between align-items-center faq-section">
            <div>
              <h2>Join The Learning journey Today!</h2>
              <p className="text-white" style={{ fontWeight: "500" }}>
                Transform Your child's Learning Experience With Our Interactive
                And Educational <br />
                Worksheets. Make Learning Joyous Advanture That Happens Right At
                Your Dining Table Or <br />
                Your Living Room. Give Your Child The Tools They Need To Excel
                Academically.
              </p>
            </div>
            <div>
              <Link to="ocr-rest-api">
                <Button
                  style={{ fontSize: "1rem" }}
                  variant="contained"
                  className="btn-primary p-3 rounded-pill"
                >
                  Subscribe Now
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
