import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import MetaTags from 'react-meta-tags';
import { termsAndConditions } from '../MetaTags'

const TermsAndConditions = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <MetaTags>
                <title>{termsAndConditions.title}</title>
                <meta name="description" content={termsAndConditions.description} />
                <link rel="canonical" href={termsAndConditions.canonical} />
            </MetaTags>
            <section className="breadcrumbs">
                <div className="inner">
                    <div className="container">
                        <h1>Terms And Conditions</h1>
                    </div>
                </div>
            </section>
            <section className="privacy">
                <div className="container">
                    <h2 className='mt-unset'>Terms of use</h2>
                    <p>These terms of use (“Terms”) govern the access, browsing and use by the users (“User” or “Users”, as applicable) of this website and the websites and The mobile application (which is available in android and apple app store) run by Top 5 Learning referred to below (together referred to as the “Websites” and individually as a “Website”); as well as the services rendered through the Websites (“Service” or “Services”, as applicable) which include the download and use of certain content.</p>
                    <p>Accessing and using any of the Websites implies that the User has read and accepts to be bound by these Terms without exception. In case the User does not accept the Terms or have any objection to any part of the present Terms the User must not use any of the Websites.</p>
                    <p>Top 5 Learning may modify the Terms at any time and thus we recommend that the Terms are reviewed on a regular basis by the User. The date at the beginning of these Terms refers to the latest update of these Terms, which will be applicable from the date of publication.</p>
                    <p>Some Services provided through the Websites may be subject to specific conditions or instructions that must be accepted by the User prior to the provision of the relevant Service. These specific conditions may be imposed by Top 5 Learning or by third parties. Such specific conditions shall apply in addition to the Terms and in case of conflict shall supersede the Terms. Accordingly, the User must read and accept such specific conditions before the provision of the relevant Service.</p>
                    <p>Likewise, in respect of the collection and processing of personal data, the <Link to="privacy-policy">Privacy Policy</Link> will apply.</p>
                </div>
                <div className="section-bg" >
                    <div className="container">
                        <h2 className='mt-unset'>(1) offered Services</h2>
                        <p>The Services offered by Top 5 Learning comprise several for converting image to text its call Top 5 Learning. Platforms that Top 5 Learning operates and manages through the Websites, including any of their subdomains or sections currently offered or which may be offered in the future:</p>
                        <p>The User registered in any of the Websites may access the Services of the relevant Website and may also access the Services available in the remaining Websites which are provided free of charge, subject to an individual charge or to a subscription, depending on the offer available at the time the User registers or hires the relevant Service.</p>
                        <p>These are the Services available depending on the Website from which the User is accessing:</p>
                        <p><strong>Services offered through Top 5 Learning</strong></p>
                        <p>Through the Top 5 Learning Website the User can convert image format text into editable text format. This technology is called Top 5 Learning(Optical Character Recognition). Website: <Link to="/">www.top5learning.com</Link></p>
                        <p>The Services may offer, convert 100+ language images into text. Website provides free service for 20 scans free of cost. After that, users have to choose available subscription plans. All uploaded documents are deleted after processing. We do not keep any of your data.</p>
                    </div>
                </div>
                <div className="container">
                    <h2>(2) Authorise use of website</h2>
                    <p>User is only authorized to use the Websites and the Services in accordance with the Terms and in good faith. In particular and without limitation Users (irrespective of whether they have purchased code or not) undertake that any access or downloads of any content available in the Websites or through the Services will always be the result of a genuine legitimate interest of the User and acknowledges that any method which artificially increases the number of downloads, accesses or clicks over such content is strictly prohibited under this Terms (including without limitation the generation of downloads, accesses or clicks over such content through any robots, spiders or any other mechanism, mobile application, program or tool) and will result in the cancellation of the User’s account by Top 5 Learning and the obligation for the User to indemnify Top 5 Learning for all damages suffered as a result of the User’s breach of this undertaking.</p>
                    <p>The User agrees not to use the Services negligently, for fraudulent purposes or in an unlawful manner. Likewise, the User agrees not to partake in any conduct or action that could damage the Designs, Image, interests or rights of the Websites or third parties, particularly, the owners of the Collaborators Content, the Third Party Content or the Sponsored Content.</p>
                    <p>The User will not interfere with the functioning of the Websites or in the Services, in particular will not impersonate another user or person. The User agrees not to carry out any action that may damage, make unavailable, overload, deteriorate or impede the normal use of the Websites or the Services, which may impact the security of the Websites or the Services, or which may in any way interfere with the Services offered by Top 5 Learning.  It is prohibited to use robots, spiders or any other mechanism, mobile application, program or tool to access, copy or control any part of the Websites or the Services in any way which is contrary to the ordinary use of the Websites or which infringes Online Top 5 Learning interests, without its express prior authorization. Likewise, it is prohibited to obtain or attempt to obtain the contents of the Websites using any method or system not expressly authorized by Top 5 Learning or which is not the ordinary method of accessing the Websites.</p>
                    <p>The User shall be responsible of ensuring prior to using the Services that the features of the Services meet the User’s needs and that has all requirements, equipment and software necessary for this purpose and of any costs required for the use of the Services.</p>
                    <p>The rights granted to the User under these Terms are personal and shall not be assigned to any third party (including affiliates or entities part of the same group of companies) totally or partially, by any mean, without the prior, express and written consent from Top 5 Learning.</p>
                </div>
                <div className="section-bg" >
                    <div className="container">
                        <h2 className='mt-unset'>(3) Registration</h2>
                        <p>In order to use certain Services, the User must register; create a new account with Name and Email ID and activate an account by verifying email ID. To this end, the User must provide a valid Email ID  where the User will receive notifications related to the Services. If the User is a legal entity or business, its username must be its full name or corporate name and the person registering on behalf of the legal entity or business declares that he/she is duly authorized to bind such legal entity or business and that such legal entity or business shall be bound to these Terms.</p>
                        <p>The User agrees to provide the mandatory information required for registration and also acknowledges that such information is true, complete and up to date. The User is solely responsible of keeping such information updated. Should the User provide false, outdated or incomplete information, or should Top 5 Learning have reasons to suspect it, Top 5 Learning reserves the right to suspend or cancel the User’s account.</p>
                        <p>The User must protect and keep confidential the account password and must not disclose it to third parties. The User must neither allow other Users to access the Services through the User’s account nor use the account of another User to access the Services. If Top 5 Learning found that user are sharing his information to other user or there are multiple users are using same services or multiple users are downloading UI or Source Code  from one purchased  one user are login to multiple devices then Top 5 Learning reserves the right to suspend or cancel the User’s account. Without prior notice to the user and will not refund any purchase amount.</p>
                        <p>The User is responsible for all operations carried out through the User’s account through any device. If the User suspects that another user is using his account he/she should immediately inform Top 5 Learning.</p>
                        <p>Top 5 Learning may suspend or cancel the account of the User if it considers that the User has breached these Terms.</p>
                    </div>
                </div>
                <div className="container">
                    <h2>(4) Content</h2>
                    <p>Top 5 Learning provides convert image to text service all content of user upload by are deleted after processing. We do not keep any of your data.</p>
                    <p>If you have any question regarding any content or believe that it infringes any right, that it does not comply with these Terms, or that is inappropriate, you can do so by contacting Top 5 Learning as indicated in Section 7 or following the instructions provided on the Websites for a copyright complaint</p>
                    <p><strong>4.1. Top 5 Learning Own Content</strong></p>
                    <p>The Top 5 Learning Own Contents are offered under the conditions stated from time to time in the relevant Website (including, without limitation, restrictions to the number of downloads per day) and its use is allowed in the terms set out in Section 7 and/or in Section 8, as applicable.</p>
                    <p><strong>4.2. Collaborators Content</strong></p>
                    <p>Collaborators’ Contents are offered in the terms stated at the relevant time in the relevant Website (including, without limitation, daily download limits), and its use is allowed as stated in Sections 7 and 8, as applicable.</p>
                    <p>When offering convert service, Top 5 Learning acts as a mere intermediary between the User that Process such conversation and the User, providing the User with the access and subscription to the process Content.</p>
                    <p>Top 5 Learning makes reasonable efforts in guaranteeing the lawfulness of the Collaborators Content. However, Top 5 Learning cannot monitor or control all such Collaborators Content. For such reason, the User acknowledges and agrees that Top 5 Learning shall not be responsible for evaluating the originality, the non-infringement of third party rights and the lawfulness of such Collaborator Contents and that Top 5 Learning does not guarantee nor is liable in respect of any such Contents, except for those cases expressly provided in the applicable regulations.</p>
                </div>
                <div className="section-bg" >
                    <div className="container">
                        <h2 className='mt-unset'>(5) Responsibility</h2>
                        <p>The User acknowledges and agrees that uses the Websites and its Services at the User’s own risk and under the User’s responsibility and therefore Top 5 Learning does not accept any responsibility for misuse or use in breach of these Terms.</p>
                        <p>The User will be responsible for any damages to Top 5 Learning resulting from the User’s use of the Websites and the Services in breach of the Terms and accepts to indemnify Top 5 Learning and its directors, employees, agents and representatives from any liability in which they may incur resulting from the User’s breach of these Terms.</p>
                        <p>Top 5 Learning does not warrant the availability or continuity of the Websites and the Services, neither its reliability, quality, completeness, accuracy or whether they are fit for a specific purpose or activity.</p>
                        <br />
                        <p>As way of example and without limitation, Top 5 Learning shall not be liable for any damages that may result from:</p>
                        <ul>
                            <li>Interruptions, viruses, technical problems, interferences, omissions, unavailability, power cuts, failure of the telecommunication networks or the User’s equipment which are not Online Top 5 Learning responsibility</li>
                            <li>Delays or unavailability of the Websites and the Services due to deficiencies or traffic overload on the Internet, in the communication network or the electricity grid.</li>
                        </ul>
                        <br />
                        <p><mark><i>SAVE FOR ONLINE Top 5 Learning WILFUL MISCONDUCT, AND THOSE CASES IN WHICH DUE TO THE SPECIFIC CIRCUMSTANCES OF THE USER INVOLVED OR THE NATURE OF THE MATTER, APPLICABLE LAW PROVIDES THAT LIABILITY CANNOT BE LIMITED BY AGREEMENT, USE OF THE WEBSITES AND THE SERVICES IS AT SOLE RISK OF THE USER AND www.top5learning.com SHALL NOT BE LIABLE FOR ANY DAMAGE OF ANY KIND CAUSED TO THE USER AS A RESULT OF USING THE WEBSITES AND/OR THE SERVICES.</i></mark></p>
                        <p>In accordance with Section 4 above regarding Third Party Content and Sponsored Content, Top 5 Learning acts exclusively as a provider of a search service as requested by the User, without assuming any responsibility for search results.</p>
                        <p>The insertion of links in the Services does not imply any relationship, recommendation or supervision by Top 5 Learning of the linked website and accordingly, Top 5 Learning does not accept any liability in relation to the content of any linked website except in the specific circumstances provided by applicable law.</p>
                        <p>In accordance with Section 4 above, regarding the Collaborator Contents, Top 5 Learning acts exclusively as the provider of the intermediation service between the offer of Collaborator Contents and the Users interested in downloading them, without any liability in relation to such contents, except for those cases expressly stated by Law.</p>
                        <p>Top 5 Learning will make all reasonable commercial efforts in order to ensure the accuracy of keywords and descriptions, as well as the identification of Content as exclusively for editorial use or any equivalent expression. However, Top 5 Learning <mark>NEITHER WARRANTS NOR MAKES ANY REPRESENTATION REGARDING ANY KEYWORD, TITLE OR DESCRIPTION; OR THE IDENTIFICATION OR LACK OF IDENTIFICATION OF ANY VISUAL CONTENT AS EXCLUSIVELY FOR EDITORIAL USE.</mark> For this reason and notwithstanding any other liability limitation that may be applicable in accordance with these Terms, Top 5 Learning shall neither indemnify nor assume any liability in connection with any claim resulting from inaccurate keywords, titles or descriptions, or from the use of the visual content identified as exclusively for editorial use.</p>
                    </div>
                </div>
                <div className="container">
                    <h2>(7) Intellectual Property</h2>
                    <p>All intellectual property rights over the Websites, the Services, and/or the Websites Content, its design, and source code, and all content included in any of them (including without limitation text, images, animations, data bases, graphics, logos, trademarks, icons, buttons, pictures, videos, sound recordings, etc.) belong or are licensed to Top 5 Learning.</p>
                    <p>Except as expressly authorized under Section 8, the reproduction or distribution, as well as transformation, producing any derivative works of any kind, public communication, making available, extraction, reuse or any other use of the Websites, the Services, the Websites Content or any of its parts, is strictly forbidden.</p>
                </div>
                <div className="section-bg" >
                    <div className="container">
                        <h2 className='mt-unset'>(8) Changes and Closure of the website</h2>
                        <p>Top 5 Learning may, at any moment, and without incurring in any responsibility towards the User, modify the content of the Websites or the Services, limit or modify the conditions or cease to provide some or all the Services and features available or deactivate and delete all or some of the User accounts and their corresponding information. However, Top 5 Learning will comply with its obligations regarding the keeping of records in relation of certain transactions for the relevant period as provided by applicable law or refund the Users of Subscriptions in force at that time with the proportional part of the price not accrued before the termination.</p>
                    </div>
                </div>
                <div className="container">
                    <h2>(9) General And Contact Information</h2>
                    <p>The use of the Websites and the Services shall be governed by Indian Law. Except for those cases in which applicable regulations impose a specific jurisdiction, any dispute in connection with these Terms shall be resolved by the Courts of Gujarat, and the Parties waive expressly any other applicable jurisdiction.</p>
                    <p>If any provision in these Terms is declared to be invalid or unenforceable, it shall be deemed that it had not been included. The remaining provisions in these Terms shall not be affected in any way.</p>
                    <p>You may contact Top 5 Learning for any query or claim in:</p>
                    <a href='mailto:support@arkayapps.com'>support@arkayapps.com</a>
                </div>
            </section>
        </>
    )
}

export default TermsAndConditions
