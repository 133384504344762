import React, { useState, useEffect } from "react";
import Languages from "../components/Languages";
import allLanguages from "../languages.json";
import { trim } from "../functions";
import MetaTags from "react-meta-tags";
import { ocrRestApi } from "../MetaTags";
import { Alert, CircularProgress, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { apiPost } from "../functionsAPI";
import ApiIcon from "../assets/images/api.svg";
import TimeLeftIcon from "../assets/images/time-left.svg";
import MoneyIcon from "../assets/images/save-money.svg";
import UseIcon from "../assets/images/tap.svg";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OcrImage from "../assets/images/rest-api.svg";

const OcrApi = (props) => {
  const [loading, setLoading] = useState(false);
  const progress = loading ? <CircularProgress size={30} /> : "";

  let languageURL = props.match.url;
  const [language, setLanguage] = useState({ name: null });
  const [result, setResult] = useState("");
  const [error, setError] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    let lang = allLanguages.find(
      (lang) => "/" + lang.restApiUrl === languageURL
    );
    if (lang) {
      setLanguage(lang);
    } else {
      props.history.push("/oops");
    }
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (number.length < 10) {
      setError("Please enter a valid number");
      setLoading(false);
    } else {
      setResult("");
      setError(false);
      setLoading(true);
      const data = {
        name: trim(name),
        email: trim(email),
        number: trim(number),
        message: trim(message),
      };
      apiPost("/customer/send-message?type=api", data)
        .then((res) => {
          setResult("Thanks! Message sent successfully");
          setName("");
          setEmail("");
          setNumber("");
          setMessage("");
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError(err);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <MetaTags>
        <title>
          OCR REST API For {language.name} Language | Online OCR Convertor
        </title>
        <meta
          name="description"
          content={`OCR REST API for ${language.name} Language helps to extract ${language.name} language text from Image. Convert ${language.name} image to text Convert make easy.`}
        />
        <link
          rel="canonical"
          href={"https://onlineocrconverter.com" + languageURL}
        />
      </MetaTags>
      <section className="breadcrumbs">
        <div className="inner">
          <div className="container">
            <h1>Get your OCR API for {language.name} Language</h1>
            <p>
              We provides REST API in {language.name} Language for
              programmatically OCR converter.
            </p>
          </div>
        </div>
      </section>
      <section id="contact" className="container">
        <Grid container spacing={2} justifyContent="space-between" center>
          <Grid
            item
            xs={12}
            md={5}
            sm={5}
            display={"flex"}
            alignItems={"center"}
          >
            <div className="text-center">
              <img
                src={OcrImage}
                alt="Ocr Api"
                className="img-fluid rest-api-image"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} margin={"auto"}>
            <h2>OCR API</h2>
            <p>
              OnlineOCR Converter’s OCR API converts an uploaded image in common
              formats such as JPEG and PNG into words or text with location
              information and other metadata via Optical Character Recognition
              (OCR).
            </p>
            <a href="https://docs.onlineocrconverter.com/" target="_blank">
              <button type="button" class="btn btn-primary mt-2">
                Open API Demo
              </button>
            </a>
          </Grid>
        </Grid>
      </section>
      <section id="contact" className="section-bg">
        <div className="container">
          <h2>
            OnlineOCR Converter’s OCR REST API for {language.name} Language
          </h2>
          <p>
            onlineocrconverter.com provides REST API in {language.name} language
            for programmatically OCR converter. HTTP protocol interaction is
            performed. Any programming language can be use.
          </p>
          <ul>
            <li>Specific URL will be provided for Access resources.</li>
            <li>
              You can access the resource by sending HTTP request. The server
              response contains a request with the result.
            </li>
            <li>
              HTTP status code 200 will be returned if the response is
              successful.
            </li>
            <li>Dedicate support manager provide for API Integration.</li>
          </ul>
        </div>
      </section>
      <section id="ocr-cards" className="container">
        <h2 className="center">Why choose the OCR REST API?</h2>
        <div class="row row-cols-lg-4 row-cols-md-2 row-cols-1 g-4 mt-2">
          <div class="col">
            <div class="card h-100 border border-3 text-center">
              <div class="card-body">
                <img src={ApiIcon} alt="" />
                <h4 class="card-title">Your App our API</h4>
                <p class="card-text">
                  Use anywhere and convert in any language.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100 border border-3 text-center">
              <div class="card-body">
                <img src={TimeLeftIcon} alt="" />
                <h4 class="card-title">Save time</h4>
                <p class="card-text">No need to write algorithms for OCR.</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100 border border-3 text-center">
              <div class="card-body">
                <img src={MoneyIcon} alt="" />
                <h4 class="card-title">Save money</h4>
                <p class="card-text">
                  No need of extra cloud server for your OCR processes.
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100 border border-3 text-center">
              <div class="card-body">
                <img src={UseIcon} alt="" />
                <h4 class="card-title">Easy to use</h4>
                <p class="card-text">
                  Easy to setup and implement, with well-documented
                  instructions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="steps" className="section-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <h2>
                Documentation for how to use OCR REST API in {language.name}{" "}
                Language
              </h2>
              <p>
                Integrating API is too easy if you have some basic knowledge.
                Follow the below steps to start working with APIs.
              </p>
              <div className="my-3">
                <p>
                  <strong>Step 1 :</strong> Create the API Token
                </p>
                <p className="steps-paragraph">
                  Specific URL will be provided for Access resources.
                </p>
              </div>
              <div className="my-3">
                <p>
                  <strong>Step 2 :</strong> Create the API Token
                </p>
                <p className="steps-paragraph">
                  You can access the resource by sending HTTP request. The
                  server response contains a request with the result.
                </p>
              </div>
              <div className="my-3">
                <p>
                  <strong>Step 3 :</strong> Create the API Token
                </p>
                <p className="steps-paragraph">
                  HTTP status code 200 will be returned if the response is
                  successful.
                </p>
              </div>
            </div>
            <div className="col-md-3 d-flex justify-content-center align-items-center">
              <Link to="docs">
                <button type="button" class="btn btn-primary px-4 mt-2">
                  Know More
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section id="banner">
        <div className="main">
          <div className="child">
            <div className="container">
              <h1>
                OnlineOCR Converter services that converts images, scans doc
                images text into editable files by using OCR Technology.
              </h1>
              <p>
                Click here to Open{" "}
                <a href="https://docs.onlineocrconverter.com/" target="_blank">
                  API Demo
                </a>{" "}
                or{" "}
                <a
                  href="https://onlineocrconverter.com/online-ocr-converter.postman_collection.json"
                  download
                >
                  Postman File
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="contact" className="section-bg">
        <div className="container">
          <h2 className="mb-5">Frequently Asked Questions</h2>
          <div id="faq" className="container">
            <h2>1. What is API Key?</h2>
            <p>
              API keys are useful for sending API requests. Without an API key,
              we cannot request data from the server.
            </p>

            <h2>2. Where can i find API?</h2>
            <p>{`You can find the API key under “My Profile” -> “API Key” -> “API Details”`}</p>

            <h2>3. Can I use the API on a free subscription?</h2>
            <p>Yes, you can use it with a free subscription.</p>

            <h2>4. How many images can I convert with a free subscription?</h2>
            <p>
              You can convert up to 5 images per day with a free subscription.
            </p>

            <h2>5. Can I use this API with PHP, Java, or React?</h2>
            <p>Yes. you can.</p>

            <h2>6. Is this API helpful for Flutter?</h2>
            <p>
              Yes, with this API, you simply need to provide an image, and it
              will return the JSON text.
            </p>

            <h2>7. Is login required to obtain an API key?</h2>
            <p>Yes, users must log in to obtain an API key.</p>

            <h2>8. Do I need a separate subscription for the API?</h2>
            <p>
              No, the same subscription will work. There is no need for an
              additional API subscription plan for API.
            </p>

            <h2>9. I require a daily limit of more than 1000 images.</h2>
            <p>
              Yes. you can contact us by mail at{" "}
              <a href="mailto:info@arkayapps.com">info@arkayapps.com</a>
            </p>

            <h2>10. Where can I find detailed documentation for the API?</h2>
            <p>
              You can find it here (
              <a href="https://onlineocrconverter.com/docs" target="_blank">
                {" "}
                https://onlineocrconverter.com/docs{" "}
              </a>
              )
            </p>

            <h2>
              11. I am familiar with Postman. Can you provide API examples for
              it?
            </h2>
            <p>
              Yes, you can find postman example at ({" "}
              <a
                href="https://onlineocrconverter.com/online-ocr-converter.postman_collection.json"
                target="_blank"
                download
              >
                https://onlineocrconverter.com/online-ocr-converter.postman_collection.json
              </a>{" "}
              ). Remember to change API key with yours.
            </p>
          </div>
        </div>
      </section>
      <section id="contact">
        <div className="container">
          <div className="shadow-lg p-5">
            <h2>Questions?</h2>
            <p>
              Please feel free to ask all your questions about the OCR API for{" "}
              {language.name} Language.
            </p>
            <form onSubmit={onSubmit}>
              <input
                type="text"
                required
                className="form-control"
                value={name}
                minLength={3}
                maxLength={50}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your name *"
              />
              <input
                type="email"
                required
                className="form-control"
                value={email}
                minLength={3}
                maxLength={50}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email *"
              />

              <PhoneInput
                className="phone-input-field"
                country={"in"}
                value={number}
                countryCodeEditable={false}
                onChange={(value) => setNumber("+" + value)}
                inputProps={{
                  required: true,
                  autoFocus: true,
                }}
              />

              <textarea
                className="form-control"
                required
                rows={5}
                value={message}
                placeholder="Enter your message *"
                minLength={5}
                maxLength={255}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>

              <LoadingButton
                type="submit"
                loading={loading}
                className="mt-4"
                loadingIndicator="Sending..."
                variant="contained"
              >
                Submit
              </LoadingButton>
              <p className="mt-5 text-center">{progress}</p>
            </form>
            {result ? (
              <div className="result">
                <Alert severity="success">{result}</Alert>
              </div>
            ) : (
              ""
            )}
            {error ? <Alert severity="error">{error}</Alert> : ""}
          </div>
        </div>
      </section>
      <section className="section-bg">
        <Languages
          heading={"Get your OCR API For More Than 100+ Languages"}
          restApi={true}
        />
      </section>
    </>
  );
};

export default OcrApi;
