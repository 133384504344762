import React, { useEffect } from 'react'
import MetaTags from 'react-meta-tags';
import { privacyPolicy } from '../MetaTags'

const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <MetaTags>
                <title>{privacyPolicy.title}</title>
                <meta name="description" content={privacyPolicy.description} />
                <link rel="canonical" href={privacyPolicy.canonical} />
            </MetaTags>
            <section className="breadcrumbs">
                <div className="inner">
                    <div className="container">
                        <h1>Privacy Policy</h1>
                    </div>
                </div>
            </section>
            <section className="privacy">
                <div className="container">
                    <p>We only collect the minimum data whatever we need on Top 5 Learning Services and we keep with the data with us as long as we need.</p>
                    <h2>Personal Data</h2>
                    <p>Without login we can't collect any personal information. On login using email or Google we collect your personal information like first name, lastname and  Email. For Pro user We need your billing information. Your credit card and Billing details are not stored on our servers. Instead we use a trusted 3rd party payment provider (Stripe) to handle the subscription and billing. </p>
                </div>
                <div className="section-bg" >
                    <div className="container">
                        <h2 className='mt-unset'>Top 5 Learning Image Store</h2>
                        <p>We log your IP address to track no scans done by one user.</p>
                        <p><b><i>All Uploaded documents are deleted after processing is completed. We do not keep any of your data.</i></b></p>

                        <h2>Visitor tracking</h2>
                        <p>We use Google Analytics and other 3rd party tools on our website. This data is not combined with the Top 5 Learning data.</p>
                    </div>
                </div>
                <div className="container">
                    <h2>Changes to the privacy policy</h2>
                    <p>The Privacy Policy may be changed occasionally and you are advised to check it from time to time.</p>

                    <h2>Applicable Law</h2>
                    <p>The Top 5 Learning team headquarter is in Gujarat (India).</p>
                </div>
            </section>
        </>
    )
}

export default PrivacyPolicy
