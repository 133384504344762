import { Alert, Button, Chip, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { apiDelete, apiGet } from "../../functionsAPI";

const ApiKey = (props) => {
  const [loading, setLoading] = useState(false);
  const progress = loading ? <CircularProgress size={30} /> : "";

  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    apiKey: "",
  });

  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");

  const getProfile = () => {
    setLoading(true);
    apiGet("/customer/profile")
      .then((res) => {
        setUser(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const [apiKey, setApiKey] = useState(null);
  const getApiKey = () => {
    setLoading(true);
    apiGet("/customer/api-key")
      .then((res) => {
        setApiKey(res.data.api_key);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getProfile();
    getApiKey();
    // eslint-disable-next-line
  }, []);

  const handleGenerateKey = () => {
    setLoading(true);
    apiGet("/customer/api-key/new")
      .then((res) => {
        setApiKey(res.data.api_key);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleDeleteKey = () => {
    setLoading(true);
    apiDelete("/customer/api-key")
      .then((res) => {
        setApiKey(null);
        setAlertType("success");
        setAlertMessage("API Key Deleted");
        setLoading(false);
        setTimeout(() => {
          setAlertType(null);
          setAlertMessage("");
        }, 3000);
      })
      .catch((err) => {
        setAlertType("error");
        setAlertMessage("Something went wrong! Please try again.");
        setLoading(false);
      });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(apiKey);
    setAlertType("success");
    setAlertMessage("Copied to clipboard");
    setTimeout(() => {
      setAlertType(null);
      setAlertMessage("");
    }, 3000);
  };

  return (
    <>
      <h3>API Details</h3>
      <table>
        <tr>
          <td>Name</td>
          <th>
            {user.first_name} {user.last_name}
          </th>
        </tr>
        <tr>
          <td>Email</td>
          <th>{user.email}</th>
        </tr>
        <tr>
          <td>API Key</td>
          <th>
            {apiKey ? (
              <>
                {apiKey} <br />
                <Chip label="copy" variant="outlined" onClick={handleCopy} />
              </>
            ) : (
              <span className="verify" onClick={handleGenerateKey}>
                <span>Generate Now</span>
              </span>
            )}
          </th>
        </tr>
      </table>
      {progress}
      {apiKey ? (
        <Button
          variant="outlined"
          color="error"
          disabled={loading}
          onClick={handleDeleteKey}
        >
          Delete API Key
        </Button>
      ) : (
        ""
      )}
      {alertType ? (
        <>
          <br />
          <br />
          <Alert severity={alertType}>{alertMessage}</Alert>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ApiKey;
