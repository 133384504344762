import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import EnterprisePlan from './EnterprisePlan'
import MostPopularPlan from './MostPopularPlan'

export default function PlansAndPricing() {

    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <section>
                <Container>
                    <h2 className='center'>Checkout Our Pricing &amp; Plans</h2>
                    <Row className='pricing justify-content-center'>
                        <Col xl={12} lg={12} md={12}>
                            <Row>
                                <Col md={4} className="my-auto">
                                    <div className="type-1">
                                        <h3>Free</h3>
                                        <div className="price">
                                            <span className="currency">$</span>
                                            <span className="amount">0</span>
                                        </div>
                                        <p className='validity'>Per Month</p>
                                        <div className="features">
                                            <p>5 Total Free Worksheets</p>
                                            
                                        </div>
                                        <Link to="/sign-in" className="btn-action">Free Sign Up</Link>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <MostPopularPlan />
                                </Col>
                                <Col md={4} className="my-auto">
                                    <EnterprisePlan />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
